/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * The config block for setting up all the url routing.
 */
angular.module('index').config(['$routeProvider', '$locationProvider', 
        function indexRouteConfig($routeProvider, $locationProvider) {

    // Disable HTML5 mode (use # for routing)
    $locationProvider.html5Mode(false);

    // Clear hash prefix to keep /#/thing/bat URL style
    $locationProvider.hashPrefix('');

    /**
     * Attempts to re-authenticate with the Guacamole server, sending any
     * query parameters in the URL, along with the current auth token, and
     * updating locally stored token if necessary.
     *
     * @param {Service} $injector
     *     The Angular $injector service.
     * 
     * @returns {Promise}
     *     A promise which resolves successfully only after an attempt to
     *     re-authenticate has been made. If the authentication attempt fails,
     *     the promise will be rejected.
     */
    var updateCurrentToken = ['$injector', function updateCurrentToken($injector) {

        // Required services
        var $location             = $injector.get('$location');
        var authenticationService = $injector.get('authenticationService');

        // Re-authenticate including any parameters in URL
        return authenticationService.updateCurrentToken($location.search());
    }];

    // only for record not useed
    var dealCodeLoginInitCode = function () {
            let codeParam = getUrlParams('code');
            let connTypeParam = getUrlParams('connType');
            let filter = getUrlParams('filter');
            let source = getUrlParams('source');
            let connTypeParamLS = localStorageService.getItem('connType');
            let filterLS = localStorageService.getItem('filter');
            let sourceLS = localStorageService.getItem('source');
            // console.log(connTypeParamLS);
            // console.log(filterLS);
            // console.log(codeParam);
            // console.log(connTypeParam);
            // console.log(filter);
            let callCodeLogin = false;
            if (connTypeParamLS !== undefined && filterLS !== undefined && sourceLS !== undefined
                && connTypeParam !== null && filter !== null && source !== null && !callCodeLogin) {
                console.log('when login status: skip login module, so need recall codeLogin api to update userinfo!');
                callCodeLogin = true;
                let paramObj = {
                    code: codeParam,
                    connType: connTypeParam,
                    filter: filter,
                    source: source
                };
                console.log('call codeLogin api!');
                authenticationService.authenticate2(paramObj)
                .then(function updateLoginFromIImakeComplete() {
                    console.log('call codeLogin api succ!');
                    let isFilter = localStorageService.getItem('filter');
                    if (isFilter == 1) {
                        console.log('call idle connection api!');
                        userPageService.getIdleConnectionForIImakeUser()
                        .then(function idleConnectionRetrieved(idleInfo) {
                            console.log('call idle connection api succ');
                            if (idleInfo.url !== '/home') {
                                console.log('has idle connection and go client page!');
                                $location.url(idleInfo.url);
                                route.resolve();
                            } else {
                                console.log('has 0 idle connetion!');
                                route.reject();
                            }  
                        })
                        ['catch'](function idleConnectionGetFailed() {
                            console.log('call idle connection api fail!');
                            route.resolve();
                        });
                    }
                })
                ['catch'](function updateLoginFromIImakeFailed() {
                    console.log('call codeLogin api fail!');
                    localStorageService.removeItem('filter');
                    $location.url('/');
                    // route.reject();
                });
            }
            
            if (connTypeParamLS !== null && filterLS !== null && sourceLS !== null
                && connTypeParam == null && filter == null && source == null) {
                console.log('when unlogin status: first into login module call codeLogin api, second into this!');
                userPageService.getIdleConnectionForIImakeUser()
                .then(function idleConnectionRetrieved(idleInfo) {
                    console.log('call idle connection api succ');
                    if (idleInfo.url !== '/home') {
                        console.log('has idle connection and go client page!');
                        $location.url(idleInfo.url);
                        route.resolve();
                    } else {
                        console.log('has 0 idle connetion!');
                        $location.url('/home');
                        route.reject();
                    }  
                })
                ['catch'](function idleConnectionGetFailed() {
                    console.log('call idle connection api fail!');
                    route.resolve();
                });
            }
    }

    /**
     * Redirects the user to their home page. This necessarily requires
     * attempting to re-authenticate with the Guacamole server, as the user's
     * credentials may have changed, and thus their most-appropriate home page
     * may have changed as well.
     *
     * @param {Service} $injector
     *     The Angular $injector service.
     * 
     * @returns {Promise}
     *     A promise which resolves successfully only after an attempt to
     *     re-authenticate and determine the user's proper home page has been
     *     made.
     */
    var routeToUserHomePage = ['$injector', function routeToUserHomePage($injector) {

        // Required services
        var $location       = $injector.get('$location');
        var $q              = $injector.get('$q');
        var userPageService = $injector.get('userPageService');
        var localStorageService = $injector.get('localStorageService');
        var authenticationService = $injector.get('authenticationService');

        // Promise for routing attempt
        var route = $q.defer();



        let codeParam = getUrlParams('code');
        let connTypeParam = getUrlParams('connType');
        let filter = getUrlParams('filter');
        let source = getUrlParams('source');
        let connTypeParamLS = localStorageService.getItem('connType');
        let filterLS = localStorageService.getItem('filter');
        let sourceLS = localStorageService.getItem('source');
        // console.log(connTypeParamLS);
        // console.log(connTypeParam);
        // console.log(filterLS);
        // console.log(filter);
        // console.log(sourceLS);
        // console.log(source);
        console.log('codeParam:' + codeParam);
        

        if (connTypeParamLS == null && filterLS == null && sourceLS == null
            && connTypeParam !== null && filter !== null && source !== null) {
            console.log('处理来自' + source+ '的连接，当前未登录，会经过login模块调用codeLogin成功后，获取空闲连接信息!');
        }
        if (connTypeParamLS !== null && filterLS == null && sourceLS !== null
            && connTypeParam !== null && filter !== null && source !== null) {
            console.log('处理来自' + source + '外部的连接，当前已登录过，为避免切换用户登录时，用户信息不同步，需要强制调用一次codeLogin接口成功后再次获取空闲连接信息!');


            let paramObj = {
                code: codeParam,
                connType: connTypeParam,
                filter: filter,
                source: source
            };
            console.log('call codeLogin api!');
            authenticationService.authenticate2(paramObj, 2)
            .then(function updateLoginFromIImakeComplete(data) {
                console.log('call codeLogin api succ!');
                console.log(paramObj);
                for (let key in paramObj) {
                    localStorageService.setItem(key, paramObj[key]);
                }
                let isFilter = localStorageService.getItem('filter');
                console.log(isFilter);
                if (isFilter == 1) {
                    console.log('call idle connection api!');
                    userPageService.getIdleConnectionForIImakeUser()
                    .then(function idleConnectionRetrieved(idleInfo) {
                        console.log('call idle connection api succ');
                        console.log('idle-conn-Info:' + idleInfo);
                        if (idleInfo.url !== '/home') {
                            console.log('has idle connection and go client page!');
                            $location.url(idleInfo.url);
                            route.resolve();
                        } else {
                            console.log('has 0 idle connetion!');
                            route.reject();
                        }  
                    })
                    ['catch'](function idleConnectionGetFailed() {
                        console.log('call idle connection api fail!');
                        route.resolve();
                    });
                }
            })
            ['catch'](function updateLoginFromIImakeFailed(error) {
                console.log(error);
                console.log('call codeLogin api fail!');
                localStorageService.removeItem('filter');
                $location.url('/');
                // route.reject();
            });


            // userPageService.getIdleConnectionForIImakeUser()
            // .then(function idleConnectionRetrieved(idleInfo) {
            //     console.log('call idle connection api succ');
            //     if (idleInfo.url !== '/home') {
            //         console.log('has idle connection and go client page!');
            //         $location.url(idleInfo.url);
            //         route.resolve();
            //     } else {
            //         console.log('has 0 idle connetion!');
            //         $location.url('/home');
            //         route.reject();
            //     }  
            // })
            // ['catch'](function idleConnectionGetFailed() {
            //     console.log('call idle connection api fail!');
            //     route.resolve();
            // });
        }
        
        // let callCodeLogin = false;
        // if (connTypeParamLS !== undefined && filterLS !== undefined && sourceLS !== undefined
        //     && connTypeParam !== null && filter !== null && source !== null && !callCodeLogin) {
        //     console.log('when login status: skip login module, so need recall codeLogin api to update userinfo!');
        //     callCodeLogin = true;
        //     let paramObj = {
        //         code: codeParam,
        //         connType: connTypeParam,
        //         filter: filter,
        //         source: source
        //     };
        //     console.log('call codeLogin api!');
        //     authenticationService.authenticate2(paramObj, 2)
        //     .then(function updateLoginFromIImakeComplete(data) {
        //         console.log('call codeLogin api succ!');
        //         console.log(paramObj);
        //         for (let key in paramObj) {
        //             localStorageService.setItem(key, paramObj[key]);
        //         }
        //         let isFilter = localStorageService.getItem('filter');
        //         console.log(isFilter);
        //         if (isFilter == 1) {
        //             console.log('call idle connection api!');
        //             userPageService.getIdleConnectionForIImakeUser()
        //             .then(function idleConnectionRetrieved(idleInfo) {
        //                 console.log('call idle connection api succ');
        //                 if (idleInfo.url !== '/home') {
        //                     console.log('has idle connection and go client page!');
        //                     $location.url(idleInfo.url);
        //                     route.resolve();
        //                 } else {
        //                     console.log('has 0 idle connetion!');
        //                     route.reject();
        //                 }  
        //             })
        //             ['catch'](function idleConnectionGetFailed() {
        //                 console.log('call idle connection api fail!');
        //                 route.resolve();
        //             });
        //         }
        //     })
        //     ['catch'](function updateLoginFromIImakeFailed(error) {
        //         console.log(error);
        //         console.log('call codeLogin api fail!');
        //         localStorageService.removeItem('filter');
        //         $location.url('/');
        //         // route.reject();
        //     });
        // }
        
        // if (connTypeParamLS !== null && filterLS !== null && sourceLS !== null
        //     && connTypeParam == null && filter == null && source == null) {
        //     console.log('when unlogin status: first into login module call codeLogin api, second into this!');
        //     userPageService.getIdleConnectionForIImakeUser()
        //     .then(function idleConnectionRetrieved(idleInfo) {
        //         console.log('call idle connection api succ');
        //         if (idleInfo.url !== '/home') {
        //             console.log('has idle connection and go client page!');
        //             $location.url(idleInfo.url);
        //             route.resolve();
        //         } else {
        //             console.log('has 0 idle connetion!');
        //             $location.url('/home');
        //             route.reject();
        //         }  
        //     })
        //     ['catch'](function idleConnectionGetFailed() {
        //         console.log('call idle connection api fail!');
        //         route.resolve();
        //     });
        // }



        // Re-authenticate including any parameters in URL
        $injector.invoke(updateCurrentToken)
        .then(function tokenUpdateComplete() {
            // Redirect to home page
            userPageService.getHomePage()
            .then(function homePageRetrieved(homePage) {
                // If home page is the requested location, allow through
                if ($location.path() === homePage.url)
                    route.resolve();

                // Otherwise, reject and reroute
                else {
                    $location.path(homePage.url);
                    route.reject();
                }

            })
            // If retrieval of home page fails, assume requested page is OK
            ['catch'](function homePageFailed() {
                route.resolve();
            });
        })
        ['catch'](function tokenUpdateFailed() {
            route.reject();
        });

        

        // Return promise that will resolve only if the requested page is the
        // home page
        return route.promise;

    }];
    var getUrlParams = function getUrlParams (name) {
        let paramStr = window.location.href.split('?')[1];
        if (paramStr == undefined) {
            return null;
        }
        let paramStrArr = paramStr.split('&');
        let paramObj = {};
        paramStrArr.forEach(function (item) {
            let tempArr = item.split('=');
            paramObj[tempArr[0]] = tempArr[1]
        });
        if (paramObj[name] !== undefined) {
            return paramObj[name];
        }
        return null;
    };
    

    

    // Configure each possible route
    $routeProvider

        // Home screen
        .when('/', {
            title         : 'APP.NAME',
            bodyClassName : 'home',
            templateUrl   : 'app/home/templates/home.html',
            controller    : 'homeController',
            resolve       : { routeToUserHomePage: routeToUserHomePage }
        })
        // begin md by zzw
        // http://localhost:3005/#/api/sso/codeLogin?connType=&code=XSVdCAcfanPoAOnxmx22KmGb9axo5SQbPb0OWPijM2QuXvonyD2ewNFNOLQw
        // api/sso/codeLogin?connType=&code=VVxyBz7iqSqnVHelBWosL49qvN4AT4LibhF05C04BTtAqhrIevPgFdB6AgWK
        .when('/api/sso/codeLogin', {
            title         : 'APP.NAME',
            bodyClassName : 'home',
            templateUrl   : 'app/home/templates/home.html',
            controller    : 'homeController',
            resolve       : { routeToUserHomePage: routeToUserHomePage }
        })
        .when('/home', {
            title         : 'APP.NAME',
            bodyClassName : 'home',
            templateUrl   : 'app/home/templates/home.html',
            controller    : 'homeController', 
            resolve       : { updateCurrentToken: updateCurrentToken }
            // resolve       : { 
                // updateLoginFromIImake: updateLoginFromIImake
                // routeToUserHomePage: routeToUserHomePage
            // }
        })
        // end 

        // Management screen
        .when('/settings/:dataSource?/:tab', {
            title         : 'APP.NAME',
            bodyClassName : 'settings',
            templateUrl   : 'app/settings/templates/settings.html',
            controller    : 'settingsController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Connection editor
        .when('/manage/:dataSource/connections/:id*?', {
            title         : 'APP.NAME',
            bodyClassName : 'manage',
            templateUrl   : 'app/manage/templates/manageConnection.html',
            controller    : 'manageConnectionController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Sharing profile editor
        .when('/manage/:dataSource/sharingProfiles/:id*?', {
            title         : 'APP.NAME',
            bodyClassName : 'manage',
            templateUrl   : 'app/manage/templates/manageSharingProfile.html',
            controller    : 'manageSharingProfileController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Connection group editor
        .when('/manage/:dataSource/connectionGroups/:id*?', {
            title         : 'APP.NAME',
            bodyClassName : 'manage',
            templateUrl   : 'app/manage/templates/manageConnectionGroup.html',
            controller    : 'manageConnectionGroupController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // User editor
        .when('/manage/:dataSource/users/:id*?', {
            title         : 'APP.NAME',
            bodyClassName : 'manage',
            templateUrl   : 'app/manage/templates/manageUser.html',
            controller    : 'manageUserController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // User group editor
        .when('/manage/:dataSource/userGroups/:id*?', {
            title         : 'APP.NAME',
            bodyClassName : 'manage',
            templateUrl   : 'app/manage/templates/manageUserGroup.html',
            controller    : 'manageUserGroupController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Recording player
        .when('/settings/:dataSource/recording/:identifier/:name', {
            title         : 'APP.NAME',
            bodyClassName : 'settings',
            templateUrl   : 'app/settings/templates/settingsConnectionHistoryPlayer.html',
            controller    : 'connectionHistoryPlayerController',
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Client view
        .when('/client/:id', {
            bodyClassName : 'client',
            templateUrl   : 'app/client/templates/client.html',
            controller    : 'clientController',
            reloadOnUrl   : false,
            resolve       : { updateCurrentToken: updateCurrentToken }
        })

        // Redirect to home screen if page not found
        .otherwise({
            resolve : { routeToUserHomePage: routeToUserHomePage }
        });

}]);
