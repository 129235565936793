/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * A directive which displays the recently-accessed connections nested beneath
 * each of the given connection groups.
 */
angular.module('home').directive('guacRecentConnections', [function guacRecentConnections() {

    return {
        restrict: 'E',
        replace: true,
        scope: {

            /**
             * The root connection groups to display, and all visible
             * descendants, as a map of data source identifier to the root
             * connection group within that data source. Recent connections
             * will only be shown if they exist within this hierarchy,
             * regardless of their existence within the history.
             *
             * @type Object.<String, ConnectionGroup>
             */
            rootGroups : '='

        },

        templateUrl: 'app/home/templates/guacRecentConnections.html',
        controller: ['$scope', '$injector', function guacRecentConnectionsController($scope, $injector) {

            // Required types
            var ClientIdentifier = $injector.get('ClientIdentifier');
            var RecentConnection = $injector.get('RecentConnection');

            // Required services
            var guacHistory       = $injector.get('guacHistory');

            // begin md by zzw
            var GroupListItem = $injector.get('GroupListItem');
            var dataSourceService      = $injector.get('dataSourceService');
            var activeConnectionService = $injector.get('activeConnectionService');
            var requestService         = $injector.get('requestService');
            // end

            /**
             * Array of all known and visible recently-used connections.
             *
             * @type RecentConnection[]
             */
            $scope.recentConnections = [];

            /**
             * Returns whether recent connections are available for display.
             *
             * @returns {Boolean}
             *     true if recent connections are present, false otherwise.
             */
            $scope.hasRecentConnections = function hasRecentConnections() {
                return !!$scope.recentConnections.length;
            };

            /**
             * Map of all visible objects, connections or connection groups, by
             * object identifier.
             *
             * @type Object.<String, Connection|ConnectionGroup>
             */
            var visibleObjects = {};

            /**
             * Adds the given connection to the internal set of visible
             * objects.
             *
             * @param {String} dataSource
             *     The identifier of the data source associated with the
             *     given connection group.
             *
             * @param {Connection} connection
             *     The connection to add to the internal set of visible objects.
             */
            var addVisibleConnection = function addVisibleConnection(dataSource, connection) {

                // Add given connection to set of visible objects
                visibleObjects[ClientIdentifier.toString({
                    dataSource : dataSource,
                    type       : ClientIdentifier.Types.CONNECTION,
                    id         : connection.identifier
                })] = connection;

            };

            /**
             * Adds the given connection group to the internal set of visible
             * objects, along with any descendants.
             *
             * @param {String} dataSource
             *     The identifier of the data source associated with the
             *     given connection group.
             *
             * @param {ConnectionGroup} connectionGroup
             *     The connection group to add to the internal set of visible
             *     objects, along with any descendants.
             */
            var addVisibleConnectionGroup = function addVisibleConnectionGroup(dataSource, connectionGroup) {

                // Add given connection group to set of visible objects
                visibleObjects[ClientIdentifier.toString({
                    dataSource : dataSource,
                    type       : ClientIdentifier.Types.CONNECTION_GROUP,
                    id         : connectionGroup.identifier
                })] = connectionGroup;

                // Add all child connections
                if (connectionGroup.childConnections)
                    connectionGroup.childConnections.forEach(function addChildConnection(child) {
                        addVisibleConnection(dataSource, child);
                    });

                // Add all child connection groups
                if (connectionGroup.childConnectionGroups)
                    connectionGroup.childConnectionGroups.forEach(function addChildConnectionGroup(child) {
                        addVisibleConnectionGroup(dataSource, child);
                    });

            };

            // Update visible objects when root groups are set
            // $scope.$watch("rootGroups", function setRootGroups(rootGroups) {
                // console.log(rootGroups);
                // Clear connection arrays
                // $scope.recentConnections = [];

                // Produce collection of visible objects
                // visibleObjects = {};
                // if (rootGroups) {
                //     angular.forEach(rootGroups, function addConnectionGroup(rootGroup, dataSource) {
                //         addVisibleConnectionGroup(dataSource, rootGroup);
                //     });
                // }

                // Add any recent connections that are visible
                // guacHistory.recentConnections.forEach(function addRecentConnection(historyEntry) {

                    // Add recent connections for history entries with associated visible objects
                //     if (historyEntry.id in visibleObjects) {

                //         var object = visibleObjects[historyEntry.id];
                //         $scope.recentConnections.push(new RecentConnection(object.name, historyEntry));

                //     }

                // });
                // console.log($scope.recentConnections);

            // }); 
            // end rootGroup scope watch

            // begin md by zzw
            var clientIdMap = {};
            var countActiveConnections = function countActiveConnections(dataSource, connection) {
                return connectionCount[dataSource][connection.identifier];
            };
            var iterateRootItems = function iterateRootItems (items) {
                angular.forEach(items, function (item) {
                    if (item.type == 'connection') {
                        let clientId = item.getClientURL() && item.getClientURL().split('/')[2];
                        if (clientId !== null) {
                            clientIdMap[clientId] = item;
                        }
                    } else if (item.type == 'connection-group') {
                        return iterateRootItems(item.children);
                    }
                }); 
                
            }
            $scope.templates = {
                'connection'       : 'app/home/templates/connection.html',
                'connection-group' : 'app/home/templates/connectionGroup.html'
            };
            $scope.isVisible = function isVisible(type) {
                return !!$scope.templates[type];
            };
            $scope.rootItems = [];
            $scope.$watch('rootGroups', function setContents(connectionGroups) {
                // console.log(connectionGroups);
                $scope.recentConnections = [];
                visibleObjects = {};
                if (connectionGroups) {
                    angular.forEach(connectionGroups, function addConnectionGroup(rootGroup, dataSource) {
                        addVisibleConnectionGroup(dataSource, rootGroup);
                    });
                }
                // Reset stored data
                var dataSources = [];
                $scope.rootItems = [];
                connectionCount = {};

                // If connection groups are given, add them to the interface
                if (connectionGroups) {

                    // Add each provided connection group
                    angular.forEach(connectionGroups, function addConnectionGroup(connectionGroup, dataSource) {

                        var rootItem;

                        // Prepare data source for active connection counting
                        dataSources.push(dataSource);
                        connectionCount[dataSource] = {};

                        // If the provided connection group is already a
                        // GroupListItem, no need to create a new item
                        if (connectionGroup instanceof GroupListItem)
                            rootItem = connectionGroup;

                        // Create root item for current connection group
                        else
                            rootItem = GroupListItem.fromConnectionGroup(dataSource, connectionGroup,
                                $scope.isVisible(GroupListItem.Type.CONNECTION),
                                $scope.isVisible(GroupListItem.Type.SHARING_PROFILE),
                                countActiveConnections);

                        // If root group is to be shown, add it as a root item
                        if ($scope.showRootGroup)
                            $scope.rootItems.push(rootItem);

                        // Otherwise, add its children as root items
                        else {
                            angular.forEach(rootItem.children, function addRootItem(child) {
                                $scope.rootItems.push(child);
                            });
                        }

                    });

                    // Count active connections by connection identifier
                    dataSourceService.apply(
                        activeConnectionService.getActiveConnections,
                        dataSources
                    )
                    .then(function activeConnectionsRetrieved(activeConnectionMap) {

                        // Within each data source, count each active connection by identifier
                        angular.forEach(activeConnectionMap, function addActiveConnections(activeConnections, dataSource) {
                            angular.forEach(activeConnections, function addActiveConnection(activeConnection) {

                                // If counter already exists, increment
                                var identifier = activeConnection.connectionIdentifier;
                                if (connectionCount[dataSource][identifier])
                                    connectionCount[dataSource][identifier]++;

                                // Otherwise, initialize counter to 1
                                else
                                    connectionCount[dataSource][identifier] = 1;

                            });
                        });

                    }, requestService.DIE);

                }

                // Invoke item decorator, if provided
                // if ($scope.decorator)
                //     $scope.decorator($scope.rootItems);

                iterateRootItems($scope.rootItems);
                
                // Add any recent connections that are visible
                guacHistory.recentConnections.forEach(function addRecentConnection(historyEntry) {

                    // Add recent connections for history entries with associated visible objects
                    if (historyEntry.id in visibleObjects) {

                        var object = visibleObjects[historyEntry.id];
                        $scope.recentConnections.push(new RecentConnection(object.name, historyEntry, clientIdMap[historyEntry.id]));

                    }

                });
            });
            // end
        }]

    };
}]);
