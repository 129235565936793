/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

/**
 * The controller for the home page.
 */
angular.module('home').controller('homeController', ['$scope', '$injector', 
        function homeController($scope, $injector) {

    // Get required types
    var ConnectionGroup  = $injector.get('ConnectionGroup');
    // var GroupListItem    = $injector.get('GroupListItem');
            
    // Get required services
    var authenticationService  = $injector.get('authenticationService');
    var connectionGroupService = $injector.get('connectionGroupService');
    var dataSourceService      = $injector.get('dataSourceService');
    var requestService         = $injector.get('requestService');
    
    // var activeConnectionService = $injector.get('activeConnectionService');

    /**
     * Map of data source identifier to the root connection group of that data
     * source, or null if the connection group hierarchy has not yet been
     * loaded.
     *
     * @type Object.<String, ConnectionGroup>
     */
    $scope.rootConnectionGroups = null;

    /**
     * Array of all connection properties that are filterable.
     *
     * @type String[]
     */
    $scope.filteredConnectionProperties = [
        'name'
    ];

    /**
     * Array of all connection group properties that are filterable.
     *
     * @type String[]
     */
    $scope.filteredConnectionGroupProperties = [
        'name'
    ];

    /**
     * Returns whether critical data has completed being loaded.
     *
     * @returns {Boolean}
     *     true if enough data has been loaded for the user interface to be
     *     useful, false otherwise.
     */
    $scope.isLoaded = function isLoaded() {

        return $scope.rootConnectionGroups !== null;

    };
    
    // console.log('in home');
    // 最初始的逻辑当只有一个connection时，直接进入client界面，
    // 在navigation板块下的services下的userPageService.js内修改，且viewHistory权限有影响
    // Retrieve root groups and all descendants
    dataSourceService.apply(
        connectionGroupService.getConnectionGroupTree,
        authenticationService.getAvailableDataSources(),
        ConnectionGroup.ROOT_IDENTIFIER
    )
    .then(function rootGroupsRetrieved(rootConnectionGroups) {
        $scope.rootConnectionGroups = rootConnectionGroups;
        console.log($scope.rootConnectionGroups)
    }, requestService.DIE);

    // begin md by zzw
    
    // var countActiveConnections = function countActiveConnections(dataSource, connection) {
    //     return connectionCount[dataSource][connection.identifier];
    // };
    // $scope.templates = {
    //     'connection'       : 'app/home/templates/connection.html',
    //     'connection-group' : 'app/home/templates/connectionGroup.html'
    // };
    // $scope.isVisible = function isVisible(type) {
    //     return !!$scope.templates[type];
    // };
    // $scope.rootItems = [];
    // $scope.$watch('rootConnectionGroups', function setContents(connectionGroups) {
    //     // Reset stored data
    //     var dataSources = [];
    //     $scope.rootItems = [];
    //     connectionCount = {};

    //     // If connection groups are given, add them to the interface
    //     if (connectionGroups) {

    //         // Add each provided connection group
    //         angular.forEach(connectionGroups, function addConnectionGroup(connectionGroup, dataSource) {

    //             var rootItem;

    //             // Prepare data source for active connection counting
    //             dataSources.push(dataSource);
    //             connectionCount[dataSource] = {};

    //             // If the provided connection group is already a
    //             // GroupListItem, no need to create a new item
    //             if (connectionGroup instanceof GroupListItem)
    //                 rootItem = connectionGroup;

    //             // Create root item for current connection group
    //             else
    //                 rootItem = GroupListItem.fromConnectionGroup(dataSource, connectionGroup,
    //                     $scope.isVisible(GroupListItem.Type.CONNECTION),
    //                     $scope.isVisible(GroupListItem.Type.SHARING_PROFILE),
    //                     countActiveConnections);

    //             // If root group is to be shown, add it as a root item
    //             if ($scope.showRootGroup)
    //                 $scope.rootItems.push(rootItem);

    //             // Otherwise, add its children as root items
    //             else {
    //                 angular.forEach(rootItem.children, function addRootItem(child) {
    //                     $scope.rootItems.push(child);
    //                 });
    //             }
    //             console.log(rootItem.getActiveConnections());

    //         });

    //         // Count active connections by connection identifier
    //         dataSourceService.apply(
    //             activeConnectionService.getActiveConnections,
    //             dataSources
    //         )
    //         .then(function activeConnectionsRetrieved(activeConnectionMap) {

    //             // Within each data source, count each active connection by identifier
    //             angular.forEach(activeConnectionMap, function addActiveConnections(activeConnections, dataSource) {
    //                 angular.forEach(activeConnections, function addActiveConnection(activeConnection) {

    //                     // If counter already exists, increment
    //                     var identifier = activeConnection.connectionIdentifier;
    //                     if (connectionCount[dataSource][identifier])
    //                         connectionCount[dataSource][identifier]++;

    //                     // Otherwise, initialize counter to 1
    //                     else
    //                         connectionCount[dataSource][identifier] = 1;

    //                 });
    //             });

    //         }, requestService.DIE);

    //     }

    //     // Invoke item decorator, if provided
    //     // if ($scope.decorator)
    //     //     $scope.decorator($scope.rootItems);

    //     console.log($scope.rootItems);

    // });
    // end md by zzw
}]);
